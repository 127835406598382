import React from 'react';
import './about.css';
import portrait from '../../assets/portrait.svg';
import { Slide } from 'react-awesome-reveal';

const About = () => {
  return (
    <div className="about section__padding" id="about">
      <Slide triggerOnce>
        <div className="about__image">
          <img src={portrait} alt="portrait" />
        </div>
      </Slide>
      <Slide direction='up' triggerOnce>
      <div className="about__text">
        <div className="about__heading">
          <h1>More about me</h1>
        </div>
        <div className="about__content">
          <p> I'm Ryan, an undergraduate from Singapore currently studying computer science
              at Nanyang Technological University. I am highly 
              motivated to create innovative solutions that solve complex problems.
              With an open-minded approach and positive attitude, I am committed to 
              continuously learning and improving my skills in order to deliver 
              high-quality results for any project.</p>
        </div>
      </div>
      </Slide>
    </div>
  )
}

export default About
import React from "react";
import "./projects.css";
import { RiGithubFill, RiExternalLinkLine } from "react-icons/ri";
import gpt from "../../assets/gpt.png";
import position from "../../assets/position.gif";
import dra from "../../assets/dra.png";
import { Fade } from "react-awesome-reveal";
import football from "../../assets/football.gif";

const Projects = () => {
  return (
    <div className="projects section__padding" id="projects">
      <div className="projects__header">
        <h1>Projects</h1>
      </div>
      <div className="projects__container">
        <Fade triggerOnce>
          <div className="projects__container-project project_reverse">
            <div className="projects__container-project-text">
              <h3>Football Object Detection</h3>
              <p>
                This project leverages YOLO for detecting and tracking players,
                referees, and footballs in match footage, using K-means
                clustering to differentiate teams by t-shirt color and measure
                ball acquisition percentages. By tracking player movement with
                optical flow and perspective transformation, it
                accurately calculates distance covered and speed in meters. This
                project serves as a lead-up to my group project, MDP.
              </p>
              <div className="projects__container-project-skill">
                <p>YOLO</p>
                <p>Kmeans</p>
                <p>Optical Flow</p>
              </div>
              <div className="projects__container-project-link">
                <a
                  href="https://github.com/rphoen/football_analysis"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code <RiGithubFill />
                </a>
              </div>
            </div>
            <div className="projects__container-project-image">
              <a
                href="https://github.com/rphoen/football_analysis"
                target="_blank"
                rel="noreferrer"
              >
                <img src={football} alt="position" />
              </a>
            </div>
          </div>
        </Fade>
        <Fade triggerOnce>
          <div className="projects__container-project">
            <div className="projects__container-project-image">
              <a
                href="https://example-website-gpt.netlify.app"
                target="_blank"
                rel="noreferrer"
              >
                <img src={gpt} alt="gpt" />
              </a>
            </div>
            <div className="projects__container-project-text">
              <h3>AI Model Website</h3>
              <p>
                A website that displays the landing screen of an example company
                using GPT and AI as its product.
              </p>
              <div className="projects__container-project-skill">
                <p>React</p>
                <p>CSS</p>
              </div>
              <div className="projects__container-project-link">
                <a
                  href="https://github.com/rphoen/webite_gpt3"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code <RiGithubFill />
                </a>
                <a
                  href="https://example-website-gpt.netlify.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo <RiExternalLinkLine />
                </a>
              </div>
            </div>
          </div>
        </Fade>
        <Fade triggerOnce>
          <div className="projects__container-project project_reverse">
            <div className="projects__container-project-text">
              <h3>DLW Hackathon 2022 Finalist</h3>
              <p>
                DLW Hackathon is the biggest annual student-run hackathon in
                NTU. Here, a group of friends and I built a computer vision
                model that can identify if a hawker table is taken based on
                imagery.
              </p>
              <div className="projects__container-project-skill">
                <p>OpenCV</p>
                <p>Javascript</p>
                <p>SCSS</p>
              </div>
              <div className="projects__container-project-link">
                <a
                  href="https://github.com/rphoen/HawkerCV"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code <RiGithubFill />
                </a>
              </div>
            </div>
            <div className="projects__container-project-image">
              <a
                href="https://github.com/rphoen/HawkerCV"
                target="_blank"
                rel="noreferrer"
              >
                <img src={position} alt="position" />
              </a>
            </div>
          </div>
        </Fade>
        <Fade triggerOnce>
          <div className="projects__container-project">
            <div className="projects__container-project-image">
              <a
                href="https://drademo.netlify.app/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={dra} alt="position" />
              </a>
            </div>
            <div className="projects__container-project-text">
              <h3>DRA</h3>
              <p>
                This is a demo of a data request access workflow. The workflow
                previously utilized the Databricks API to showcase the full
                end-to-end functionality of the workflow. However, the API has
                been disabled for this demo. The workflow is now a mock workflow
                that showcases the functionality of the workflow without the
                need for the Databricks API.
              </p>
              <div className="projects__container-project-skill">
                <p>NextJS</p>
                <p>OAuth</p>
                <p>REST</p>
              </div>
              <div className="projects__container-project-link">
                <a
                  href="https://github.com/rphoen/dra_demo"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code <RiGithubFill />
                </a>
                <a
                  href="https://drademo.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo <RiExternalLinkLine />
                </a>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Projects;

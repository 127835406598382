import React from 'react'
import './miniprojects.css';
import Miniproject from '../../components/miniproject/Miniproject';
import { Slide } from 'react-awesome-reveal';

const projectsData = [
    {
        title: 'Fifa Rating Analysis',
        link: 'https://github.com/rphoen/FifaRatingAnalysis'
    },
    {
        title: 'Sudoku Solver',
        link: 'https://github.com/rphoen/sudoku'
    },
    {
        title: 'TicTacToe',
        link: 'https://tictactee.netlify.app/'
    },
    {
        title: 'MOBLIMA',
        link: 'https://github.com/rphoen/SC2002-Project'
    },
    {
        title: 'SweatPals',
        link: 'https://github.com/rphoen/SweatPals'
    },
    {
        title: 'Fluid Simulation',
        link: 'https://github.com/rphoen/fluid-sim'
    },
    {
        title: 'Movieverse',
        link: 'https://movieverse-24.netlify.app/'
    },
    {
        title: 'Public transport heatmap',
        link: 'https://github.com/rphoen/madrid_public_heatmap'
    }

]

const Miniprojects = () => {
  return (
    <div className="miniprojects section__padding">
        <div className="miniprojects__header">
            <h2>More Projects</h2>
        </div>
        <div className="miniprojects__container">
            <Slide direction='up' triggerOnce cascade damping={0.15}>
            {projectsData.map((item, index) => (
                <Miniproject title={item.title} link={item.link} key={item.title + index} />
            ))}
            </Slide>
        </div>
    </div>
  )
}

export default Miniprojects